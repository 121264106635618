/**
 * Importa as imagens estáticas
 */
import.meta.glob([
    '../images/**',
]);

import _ from 'lodash';
window._ = _;

import TomSelect from 'tom-select';

TomSelect.define('sync_after_load', function() {
    this.hook('after', 'setup', function() {
        this.on('load', function() {
            this.sync(true)
        });
    });

    this.hook('instead', 'sync', function() {
        if (Object.getOwnPropertyNames(this.options).length > 0) {
            this.__proto__.sync.apply(this, arguments)
        }
    });

    this.hook('instead', 'updateOriginalInput', function() {
        if (Object.getOwnPropertyNames(this.options).length > 0) {
            this.__proto__.updateOriginalInput.apply(this, arguments)
        }
    });
});

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
const axios = setupCache(Axios, { interpretHeader: false })
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute('content')

export { axios }

/**
 * Configuração do jQuery para o JS legado
 */
import './jquery'
import '@selectize/selectize'
import 'jquery-mask-plugin'
import 'jquery-maskmoney'

// Core jQuery UI methods
import 'jquery-ui/ui/version';
import 'jquery-ui/ui/keycode';
import 'jquery-ui/ui/unique-id';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/position';

import 'jquery-ui/ui/widgets/menu'; // Widget used by autocomplete
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-pt-BR';

/**
 * Alpine config
 */
import Alpine from 'alpinejs';
import driverList from './stores/driverList';
import pageDueDateWork from './pages/dueDate/work';
import pageJobCreate from './pages/job/create';

window.Alpine = Alpine;
document.addEventListener('DOMContentLoaded', Alpine.start);

Alpine.data('driverList', () => Object.create(
    Object.getPrototypeOf(driverList),
    Object.getOwnPropertyDescriptors(driverList),
))

Alpine.data('createWork', () => Object.create(
    Object.getPrototypeOf(pageDueDateWork),
    Object.getOwnPropertyDescriptors(pageDueDateWork),
))

Alpine.data('createJob', () => pageJobCreate)


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
