export { axios } from './bootstrap';

import numeral from 'numeral'
import ClipboardJS from 'clipboard'
import Quill from 'quill';

import 'numeral/locales/pt-br'
import 'bootstrap-sass'
import './filtertable'

let jobSearch

export { default as driverList } from './stores/driverList';
export { default as pageJobCreate} from './pages/job/create';
export { default as pageDueDateWork } from './pages/dueDate/work';
export { default as fieldDatepicker } from './components/fields/datepicker';
export { default as useSelect } from './composables/useSelect';

$(function () {
  numeral.locale('pt-br')
  numeral.defaultFormat('0,0.00')
  numeral.zeroFormat('')

  new ClipboardJS('[data-clipboard-text]')

  new ClipboardJS('.modal [data-clipboard-text]', {
    container: document.getElementById('share-job'),
  })

  $('.type-money').maskMoney({
    thousands: '.',
    decimal: ',',
  })

  $('.type-percent').maskMoney({
    allowNegative: true,
    thousands: '.',
    decimal: ',',
  })

  $('.type-cnpj').mask('00.000.000/0000-00')
  $('.type-rg').mask('00.000.000-A')
  $('.type-cpf').mask('000.000.000-00')
  $('.type-cep').mask('00000-000')
  $('.type-phone').mask('(00) 0000-0000')
  $('.type-cel').mask('(00) 00000-0000')
  $('.type-birthday').mask('00/00/0000')

  $('.type-year').mask('0000')

  $('[data-toggle="tooltip"]').tooltip()

  $('.dropdown-toggle').on('click', function(e) {
    if ($(document).width() <= 768) return;

    e.preventDefault();

    let url = $(this).attr('href');

    if (url && url !== '#' && url != '') window.location.href = url
  });

  const filterTableInput = '#search-field, #main-search'

  $('.searchable').filterTable({ inputSelector: filterTableInput })
  $('.only-search').filterTable({ inputSelector: filterTableInput, showAll: false })
  $('.initial-search').filterTable({ inputSelector: filterTableInput, restricted: true, showAll: false })

  $('#copy-address').on('click', function () {
    $('#billing_address').val($('#address').val())
    $('#billing_neighbourhood').val($('#neighbourhood').val())
    $('#billing_city').val($('#city').val())
    $('#billing_state').val($('#state').val())
    $('#billing_zip_code').val($('#zip_code').val())

    return false
  })

  $('.payment-form select').on('change', function () {
    $(this).parents('form').trigger('submit')
  })

  $('.overdue-producer').on('change', function () {
    const val = $(this).val() == 0 ? '' : $(this).val()
    window.location = '/receipts/overdue/' + val
  })

  $('.overdue-job').on('change', function () {
    const val = $(this).val() == 0 ? '' : $(this).val()
    window.location = `/job?producer_id=${val}`
  })

  $('.overdue-report').on('click', function () {
    const select = $(this).parent('form').find('select').val()
    const val = select == 0 ? '' : select

    window.location = '/receipts/overdue/' + val
    return false
  })

  $('.overdue-print').on('click', function () {
    const select = $(this).parent('form').find('select').val()
    const val = select == 0 ? '' : select

    window.location = '/pdf/overdue/' + val
    return false
  })

  $('.nf_number').on('change', function () {
    const field = $(this)
    $.ajax({
      url: '/receipts',
      data: {
        producer_id: field.data('producer-id'),
        job_name: field.data('job-name'),
        job_address: field.data('job-address'),
        director: field.data('director'),
        nf_number: field.val(),
      },
      success: function () {
        field.parent().addClass('has-success')
      },
      method: 'PUT',
    })
  })

  $('.validate-minuta').on('submit', function () {
    if ($('#minuta-panel').hasClass('has-error')) {
      $('.check-minuta').trigger('focus')
      return false
    }
  })

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
  })

  $('.print-minutas').on('click', function () {
    const minutas = $('input[type=checkbox]:checked')
    let url =
      '/pdf/exportar-minutas?_token=' +
      $('input[name=_token]').val() +
      '&driver_id=' +
      $('input[name=driver_id]').val()
    if (minutas.length > 0) {
      for (let x = 0; x < minutas.length; x++) {
        url += '&job_ids[]=' + $(minutas[x]).val()
      }

      window.location = url

      return false
    }
  })

  if ($('#producer-list').length > 0) {
    $('#producer-list').selectize({
      delimiter: ',',
      selectOnTab: true,
      createOnBlur: true,
      create: true,
      load: function (query, callback) {
        if (!query.length) return callback()
        $.ajax({
          url: '/producer/name/' + encodeURIComponent(query),
          type: 'GET',
          dataType: 'json',
          error: function () {
            callback()
          },
          success: function (res) {
            res = res.map((item) => ({ text: item, value: item }))
            callback(res)
          },
        })
      },
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="option">Adicionar <strong>' +
            escape(data.input) +
            '</strong>&hellip;</div>'
          )
        },
      },
    })
  }

  if ($('#email-list').length > 0) {
    email_list_selectize()
  }

  function email_list_selectize(el = '#email-list') {
    $(el).selectize({
      delimiter: ' ',
      selectOnTab: true,
      createOnBlur: true,
      create: true,
      createFilter: /[^@]+@[^@]+\.[^@]+/,
      load: function (query, callback) {
        if (!query.length) return callback()
        $.ajax({
          url: '/emails/' + encodeURIComponent(query),
          type: 'GET',
          dataType: 'json',
          error: function () {
            callback()
          },
          success: function (res) {
            res = res.map((item) => ({ text: item, value: item }))
            callback(res)
          },
        })
      },
    })
  }

  if ($('.filter-nf').length > 0) {
    $('.filter-nf').on('click', function () {
      if ($('.filter-nf').hasClass('btn-primary')) {
        $('tr:has(input.nf_number:not([value=""]))').show()
        $('.filter-nf').addClass('btn-default').removeClass('btn-primary')
      } else if ($('.filter-nf').hasClass('btn-default')) {
        $('tr:has(input.nf_number:not([value=""]))').hide()
        $('.filter-nf').addClass('btn-primary').removeClass('btn-default')
      }
      return false
    })
  }

  $('.change-date select').on('change', function () {
    $(this).parents('form').trigger('submit')
  })

  $('#noteModal').on('show.bs.modal', function (event) {
    const button = $(event.relatedTarget)
    const modal = $(this)

    modal.find('[name=note]').val(JSON.stringify(button.data('note')))
    modal.find('[name=message]').val(button.data('message'))
  })

  $('#reasonModal').on('show.bs.modal', function (event) {
    constbutton = $(event.relatedTarget)
    constmodal = $(this)

    modal.find('[name=id]').val(button.data('jobId'))
    modal.find('[name=reason]').val(button.data('reason'))
  })

  $('#noteModalDriver').on('show.bs.modal', function (event) {
    const button = $(event.relatedTarget)
    const note = button.data('note')
    const modal = $(this)
    const message = button.data('message')
    const message_field = modal.find('[name=message]')

    modal.find('.job').html(note.job_name.toUpperCase())
    modal.find('.producer').html(note.producer.toUpperCase())
    modal.find('.director').html(note.director.toUpperCase())
    modal.find('.message').html(message.toUpperCase())
    modal.find('[name=note]').val(JSON.stringify(note))

    message_field.val(message)

    message_field.off('keyup')
    message_field.on('keyup', function () {
      $('#noteModalDriver .message').html($(this).val().toUpperCase())
    })

    message_field.off('blur')
    message_field.on('blur', function () {
      const msg = $(this).val()
      button.data('message', msg)

      if (msg == '') {
        button.find('.user').html('')
        button.addClass('btn-default').removeClass('btn-danger')
      } else {
        button.find('.user').html(note.user)
        button.addClass('btn-danger').removeClass('btn-default')
      }

      $.post({
        url: '/comment',
        data: {
          message: $(this).val(),
          note: JSON.stringify({
            target_id: note.target_id,
            target_type: note.target_type,
          }),
        },
      })
    })
  })

  $('body').on('focusin', '#nf_due_date', function () {
    $(this).datepicker(
      $.extend($.datepicker.regional['pt-BR'], {
        minDate: new Date(),
        maxDate: '+2m',
        onSelect() {
          this.focus()
        },
        onClose() {
          this.focus()
        },
      }),
    )
  })

  $('[data-toggle="popover"]').popover({ trigger: 'hover' })

  if ($('[data-toggle="popover-email"]').length > 0) {
    $('[data-toggle="popover-email"]')
      .popover({
        trigger: 'click',
        placement: 'bottom',
        html: true,
        content: document.querySelector('template').content.cloneNode(true),
      })
      .on('inserted.bs.popover', function () {
        email_list_selectize()
      })
  }

  if ($('[data-toggle="closing-email"]').length > 0) {
    $('[data-toggle="closing-email"]')
      .popover({
        trigger: 'click',
        placement: 'bottom',
        html: true,
        title: 'Selecione o email',
        content: function() {
          const content = document.getElementById('closing-email-tpl').content.cloneNode(true)
          const form = content.querySelector('form')

          form.action = `${form.action}/${this.dataset.uuid}`
          content.querySelector('input').value = this.dataset.email
          content.querySelector('[type=button]').onclick = () => $(this).popover('hide')

          return content
        },
      })
      .on('inserted.bs.popover', () => email_list_selectize('[data-list=email]'))
  }

  $('[data-toggle="popover-success"]').popover({
    trigger: 'hover',
    template:
      '<div class="popover bg-success" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
  })

  $('[data-toggle="popover-assistant"]').popover({
    trigger: 'hover',
    template:
      '<div class="popover bg-assistant" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
  })

  $('#minuta-search').on('submit', function () {
    if ($('#minuta-number').val() != '') {
      window.location.href =
        window.location.origin + '/os/' + $('#minuta-number').val()
    }
    return false
  })

  $('#destroy-data').on('click', function () {
    $(this).parents('form').find('[name=_method]').val('DELETE')
  })

  $('.delete-check').on('click', function () {
    if (confirm('Deseja realmente excluir esta minuta?')) {
      $(this).parents('form').find('[name=_method]').val('DELETE')
      return true
    }
    return false
  })

  $('.form-driver-delete').on('click', function () {
    if (confirm('Deseja realmente excluir este motorista?')) {
      $(this).parents('form').find('[name=_method]').val('DELETE')
      return true
    }
    return false
  })

  $('.form-producer-delete').on('click', function () {
    if (confirm('Deseja realmente excluir esta produtora?')) {
      $(this).parents('form').find('[name=_method]').val('DELETE')
      return true
    }
    return false
  })

  $('.job-new').on('change', '#driver_id', function () {
    $('#vehicle').val($('#driver_id').val())
  })


  if ($('input#job_name').length && $('input[name=nf_text]').length) {
    $('input#job_name').on('keyup', function () {
      const text = $('input[name=nf_text]').val()
      if (text == $(this).data('original') || text == $(this).data('old')) {
        $('input[name=nf_text]').val($(this).val())
        $(this).data('old', $(this).val())
      }
    })
  }

  $('#producer_id:not(.njq)').on('change', function () {
    $('#aux_address').val($('#producer_id').val())
    $('#job_address').val($('#aux_address option:selected').text())
  })

  $('#producer_id:not(.njq), #driver_id:not(.njq), #job_name:not(.njq)')
    .on('change', function () {
      clearTimeout(jobSearch)
      jobSearch = setTimeout(
        function () {
          if (
            $('#producer_id').val() == 0 ||
            $('#driver_id').val() == 0 ||
            $('#job_name').val() == '' ||
            typeof $('#job_name').val() == 'undefined'
          )
            return false

          const url =
            '/job/open?producer_id=' +
            $('#producer_id').val() +
            '&driver_id=' +
            $('#driver_id').val() +
            '&job_name=' +
            $('#job_name').val().replace(' ', '+')
          $.ajax(url).done(function (response) {
            if (response.length > 0) {
              response = JSON.parse(response)

              $('#date_started_at').val(
                response.date_started_at.replace(
                  /^(\d{4})-(\d{2})-(\d{2}).*/,
                  '$3/$2/$1',
                ),
              )
              $('#date_ended_at').val(
                response.date_ended_at.replace(
                  /^(\d{4})-(\d{2})-(\d{2}).*/,
                  '$3/$2/$1',
                ),
              )
            }
          })
        }.bind(this),
        400,
      )
    })

  if ($('#total_discount').length) {
    $('#total_discount').on('keyup', function () {
      const discount = getNumeral('#total_discount').value()
      let val = ''

      if (discount !== 0) {
        const nf_value_drivers = getNumeral('[name=nf_value_drivers]').value()
        const nf_value_assistants = getNumeral('[name=nf_value_assistants]').value()
        const value = nf_value_drivers - (nf_value_drivers * discount) / 100
        val = numeral(value + nf_value_assistants).format()
      }

      $('#nf_paid_value').val(val)
    })

    $('#nf_paid_value').on('keyup', function () {
      const nf_paid_value =
        getNumeral('#nf_paid_value').value() - getNumeral('[name=nf_value_assistants]').value()
        let val = ''

      if (nf_paid_value !== 0) {
        const total_discount =
          100 - (nf_paid_value * 100) / getNumeral('[name=nf_value_drivers]').value()
        val = numeral(total_discount).format()
      }

      $('#total_discount').val(val)
    })
  }

  if ($('input.job-field').length) {
    $.ajax({
      url: '/job/name_list',
      success: function (response) {
        $('input.job-field').autocomplete({
          minLength: 0,
          source: JSON.parse(response),
          select: function (_event, ui) {
            $('[name=uuid]').val(ui.item.uuid)
            $('input.job-field').val(ui.item.label)
            $('#producer_id').val(ui.item.producer_id).trigger('change')

            ui.item.director
              .split(',')
              .forEach(item => {
                const field = $('#producer-list, #director')[0]

                if (field.selectize) {
                  field.selectize.clear()
                  field.selectize.createItem(item)
                }
              })

            return false
          },
          create: function () {
            if (window.location.pathname == '/job/create') {
              const params = new URLSearchParams(window.location.search)
              if (params.has('job_name')) {
                $('input.job-field').autocomplete(
                  'search',
                  params.get('job_name'),
                )
                $('.ui-menu-item:first-child').trigger('click')
              }
            }
          },
        })
      },
    })
  }

  $('#modalTransfer').on('shown.bs.modal', function (event) {
    $.ajax({
      url: '/job/name_list',
      success: function (response) {
        $('#modalTransfer [name=job_name][type=text]').autocomplete({
          minLength: 0,
          source: JSON.parse(response),
          appendTo: '#modalTransferForm',
          change(event, _ui) {
            const options = $(event.target).autocomplete('option').source
            let value = $(event.target).val()

            if (value !== '') value = value.toLowerCase().trim()

            search = options.find(x => (x.label && x.label.toLowerCase().trim()) == value)

            if (search !== undefined) {
              setNewJob(search)
            } else {
              $('#modalTransfer [name=uuid]').val('')
            }
          },
          select(_event, ui) {
            setNewJob(ui.item)
            return false
          },
        })
      },
    })
  })

  function setNewJob(data) {
    $('#modalTransfer [name=job_name]').val(data.label)
    $('#modalTransfer [name=director]').val(data.director)
    $('#modalTransfer [name=producer_id]').val(data.producer_id).trigger('change')
    $('#modalTransfer [name=uuid]').val(data.uuid)
  }

  if ($('.autocomplete-vehicle').length) {
    $.ajax({
      url: '/driver/vehicle_list',
      success: function (response) {
        $('.autocomplete-vehicle').autocomplete({
          source: JSON.parse(response),
        })
      },
    })
  }

  if ($("[data-type='check-all']").length) {
    $("[data-type='check-all']").on('click', function () {
      $("[data-group='" + $(this).data('target') + "']:visible").prop(
        'checked',
        this.checked,
      )
    })
  }

  if ($('.generate-nf').length) {
    $('.generate-nf').on('click', function () {
      const jobs = Array
        .from(document.querySelectorAll('[data-group="check"]:checked'))
        .map(i => `uuid[]=${i.value}`)
        .join('&')

      if (jobs.length > 0) {
        let url = `/pdf/nf?${jobs}`

        if ($(this).hasClass('with-discount')) {
          url += '&discount=true'
        }

        if ($(this).hasClass('with-law')) {
          url += '&law=true'
        }

        window.location = url
      }
      return false
    })
  }

  if ($('input.calculate').length) {
    $('input.calculate').on('click', () => {
      document.querySelector('button.calculate').dataset['content'] = [
        ...document.querySelectorAll('input.calculate:checked'),
      ]
        .reduce((total, item) => {
          return total + Number(item.dataset['jobvalue'])
        }, 0)
        .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    })
  }

  if ($('.mark-as-paid').length) {
    $('.mark-as-paid').on('click', function () {
      const fields = document.querySelectorAll('[data-group="check"]:checked')

      const uuids = Array
        .from(fields)
        .map(i => `uuid[]=${i.value}`)
        .join('&')

      if (uuids.length > 0) {
        window.location = `/receipts/received?${uuids}`
      }

      return false
    })
  }

  if ($('.print-declaration').length) {
    $('.print-declaration a').on('click', function () {
      const jobs = $('[data-group="check"]:checked')

      if (jobs.length > 0) {
        let url = '/pdf/declaration?company=' + $(this).text()

        for (let x = 0; x < jobs.length; x++) {
          url += '&jobs[]=' + $(jobs[x]).val()
        }

        window.location = url
      }
      return false
    })
  }

  if ($('.select-nav').length) {
    $('#search-field').on('focus', () => $('.select-nav a').removeClass('selected'))
    $('.select-nav a').on('click', function () {
      const source = $(this).data('type')
      const text = $(this).text()

      if (source === undefined) return false

      $('.select-nav a').removeClass('selected')
      $(this).addClass('selected')

      $(filterTableInput).val('')
      $('.vehicle-search tr, .initial-search tr').hide()
      $(`.${source}-search td span.${source}:contains('${text}${text}')`)
        .parents('tr')
        .show()
      return false
    })
  }

  if ($('.vehicle_list').length) {
    $('#search-field').on('focus', function () {
      $('.vehicle_list a').removeClass('selected')
    })
    $('.vehicle_list a').on('click', function () {
      $('.vehicle_list a').removeClass('selected')
      $(this).addClass('selected')
      const source = $(this).data('type')

      $(filterTableInput).val('')
      $('.searchable tr').hide()
      $(`.searchable td span.${source}:contains('__${$(this).text()}__')`)
        .parents('tr')
        .show()
      return false
    })
  }

  $('[data-type="nf"]').on('click', function(){
    const id = $(this).data('id')
    const select = $(`[data-target="nf"][data-id="${id}"] select`)

    setBank()

    if ($(this).prop('checked')) {
        select.prop('required', true)
        select.parent().css('visibility', 'visible');
    } else {
        select.prop('required', false)
        select.parent().css('visibility', 'hidden');
    }

    checkDec.call(this);
  });

  $('[data-type="dec"]').on('click', checkDec)

  $('[data-type="company"]').on('change', setBank)

  if (document.getElementById('editor')) {
    const editor = new Quill('#editor', {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'clean'],
          ['strike', { 'script': 'super' }, { 'script': 'sub'}],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }],
        ]
      }
    })

    const field = document.getElementById('editor_field')

    field.value = editor.root.innerHTML

    editor.on('text-change', function(_delta, _oldDelta, _source) {
      field.value = editor.root.innerHTML;
    });
  }

  $('.job_list').on('click', function(){
    let job_list = document.querySelectorAll('.job_list:checked')
    job_list = [...job_list].map(item => `<li><strong>${item.dataset.job_name}</strong></li>`)
    job_list = [...new Set(job_list)].join('')
    $('.note-editable ul').html(job_list)

    new Quill('#editor');
    // $('#summernote').summernote('code', $('.note-editable').html())
  })

})

function setPopoverAction() {
  $('#popover-action').on('click', function () {
    $(this).button('loading')

    $.ajax({
      url: $(this).attr('href'),
      method: 'delete',
      success: function (response) {
        response = JSON.parse(response)
        if (response.is_destroyed == true) {
          removeMinutaLock()
        }
      },
    })

    return false
  })
}

function checkDec() {
  const id     = $(this).data('id')
  const nf     = $(`[data-type="nf"][data-id="${id}"]`)
  const dec    = $(`[data-type="dec"][data-id="${id}"]`)
  const select = $(`[data-target="dec"][data-id="${id}"] select`)

  if (!nf.prop('checked') && !dec.prop('checked')) {
    select.prop('required', false)
    select.parent().hide()
  } else {
    select.prop('required', true)
    select.parent().show()
  }
}

function setBank()
{
  const nf = $('[data-type="nf"]:checked')
  const company = $('[data-type="company"]:visible')
  let content = ''

  if(nf.length > 0 && company.val() == "Transkinder") {
    content = '<br /><br /><strong>BRADESCO AG. 0449 C/C 650289-0<br />CNPJ. 05.489.300/0001-08</strong><br /><br />'
  } else if(company.val() == "Kinder") {
    content = '<br /><br /><strong>BRADESCO AG. 0449 C/C 647117-0<br />CNPJ. 07.163.326/0001-89</strong><br /><br />'
  }

  $('.note-editable span').html(content)

  new Quill('#editor');
  // $('#summernote').summernote('code', $('.note-editable').html())
}

function removeMinutaLock() {
  $('#minuta-panel').removeClass('has-error bg-danger')
  $('.help-block').hide()
  $('.check-minuta').popover('destroy')
  $('.check-minuta').parent().removeClass('has-error')
  $('.check-minuta').data('has-minuta', false)
}

const getNumeral = selector => numeral($(selector).val())

function toggle() {
  return {
    show: false,
    open() { this.show = true },
    close() { this.show = false },
    isOpen() { return this.show === true },
  }
}
window.toggle = toggle

function workdata() {
  return {
    time_start: '00:00',
    time_end: '00:00',
    showAlert: false,
    checkTime() {
    const ts = new Date(new Date().toDateString() + ' ' + this.time_start)
    const te = new Date(new Date().toDateString() + ' ' + this.time_end)

    this.showAlert = te < ts
    },
  }
}
window.workdata = workdata
